import { graphql } from "gatsby"
import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { PortableText } from "@portabletext/react"

const CcpaPrivacyPolicy = ({ location, data }) => {

  return (
    <Layout 
      scheme="dark" 
      location={location}
      pageCategory="agency"
    >
      <Seo title="MullenLowe U.S. — CCPA Privacy Policy" />
      <div className="container header-spacer">
        <div className="rt-pg">
          <PortableText value={data.sanityCcpaPrivacyPolicyPage._rawCopy} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    sanityCcpaPrivacyPolicyPage {
      _rawCopy
    }
  }
`

export default CcpaPrivacyPolicy
